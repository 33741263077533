<template>
<div class="container">
<div class="row justify-content-center">

    <!-- header title -->
    <Header :title="$t('profile.title')" noProject />

        <div class="col-lg-8 my-3 bg-white border py-3" v-if="auth">

            <!-- avatar -->
            <croppa v-model="myCroppa" v-if="loaded"
                :placeholder="$t('profile.upload')"
                :placeholder-font-size="12"
                remove-button-show="false"
                remove-button-color="black"
                :show-loading="true"
                :quality="2"
                :prevent-white-space="true"
                :zoom-speed="10"
                :initial-image="avatar"
                auto-sizing
            />

            <div class="mt-2">
                <!-- remove -->
                <b-button variant="danger" size="sm" class="mx-1" @click="myCroppa.remove()">
                    <b-icon icon="x" /> {{ $t('button.delete') }}
                </b-button>

                <!-- save -->
                <b-button variant="primary" size="sm" class="mx-1" @click="saveAvatar()">
                    <b-icon icon="check2-square" /> {{ $t('button.save') }}
                </b-button>            
            </div>

            <hr/>

            <InputGen v-if="user" v-model="user" :fields="profile" />

            <!-- update & delete button -->
            <div class="my-5">
                <b-button variant="primary" class="mx-1" @click="updateProfile()"><b-icon icon="check2-square" /> {{ $t('profile.update') }}</b-button>
                <!-- b-button variant="danger" class="mx-1" @click="deleteProfile()"><b-icon icon="x" /> {{ $t('profile.delete') }}</b-button -->
            </div>

        </div>
    </div>
</div>
</template>

<style scoped>
.croppa-container {
    border-radius: 400px !important;
    border: 4px dotted #c0c0c0;
    overflow: hidden;
}

.icon {
    position: absolute;
    z-index: 1000; 
}

</style>

<script>
import InputGen from "@/views/ProfileComponents/InputGen.vue";
import 'vue-croppa/dist/vue-croppa.css';
import Croppa from 'vue-croppa';

export default {
    name: "Profile",
    components: { InputGen, croppa: Croppa.component },
    async created() { 
        await this.axon("get","login","user"); 
        if(this.user.avatar==true) this.avatar = this.getAvatar(this.user.id);
        this.loaded = true;
    },
    data() {return{
        loaded: false,
        myCroppa: {},
        user: { },
        avatar: null,
        ext:  [
            'image/jpeg',
            'image/gif',
            'image/png'
            ],
        size: 3,
        profile: [
            { name: "lang" }, 
            { name: "hr" }, 
            { name: "name", label: "profile.full_name", required: "true" },
            { name: "email", disabled: true }, 
            { name: "password", type: "password", placeholder: "profile.password_empty" }, 
            { name: "password_confirmation", label: "profile.password_repeat", type: "password" },
            { name: "hr" },
            { name: "birth", type: "date" }, 
            { name: "country" }, 
            { name: "city" }, 
            { name: "phone" },
            { name: "website", type: "url" },
        ],
    }},
    methods: {

        saveAvatar() {
            this.myCroppa.generateBlob((blob) => {
                if(!blob) { 
                    this.axon("put","users/"+this.auth.id,{avatar:0});
                } else {
                    let formData = new FormData();
                    formData.append('_method','put');
                    formData.append('avatar',blob);
                    this.axon("post","users/"+this.auth.id,formData,true);
                }
            }, 'image/jpeg', 0.8)
        },

        async updateProfile() {
            await this.axon("put","users/"+this.auth.id,this.user);
            await this.authUser();
            this.goTop();
        }
    }
    
}
</script>